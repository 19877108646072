import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

console.log(process.env.REACT_APP_ENDPOINT);
const socket = io(process.env.REACT_APP_ENDPOINT);

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    socket.on('message', message => {
        let received_packet = JSON.parse(message);
        setMessages([...messages, received_packet['message']]);
        console.log("transmitting time: " + String(Date.now() - parseInt(received_packet['timestamp'])));
    });
  });

  const sendMessage = () => {
    let send_packet = {
        message: input,
        timestamp: Date.now()
    };
    socket.emit('message', JSON.stringify(send_packet));
    setInput('');
  };

  return (
    <div>
      <div>
        <h2>Chat Room</h2>
        <div>
          {messages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
        <div>
          <input
            type="text"
            value={input}
            onChange={e => setInput(e.target.value)}
          />
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
}

export default App;
